@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); 
* {
  //font-family: 'SF UI Display';
  font-family: Arial;
  font-family: "Roboto", Arial, sans-serif;
}

.bg-blue {
    background-color: $color-blue-9;
}

html {
  font-size: 13px;
}

.disp-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap-row {
  flex-wrap: wrap;
}

.justify-bw {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-all-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  .no-padding-l-r {
    padding-left: 0;
    padding-right: 0;
  }

  .no-padding-l {
    padding-left: 0;
  }

  .no-padding-r {
    padding-right: 0;
  }
}

.border-top-grey-3 {
  border-top: 1px solid $color-grey-1;
}

.border-bottom-grey-3 {
  border-bottom: 1px solid $color-grey-1;
}

.border-top-light-grey {
  border-top: 1px solid $color-grey-2;
}

.border-bottom-light-grey {
  border-bottom: 1px solid $color-grey-2;
}

.border-right-light-grey {
  border-right: 1px solid $color-grey-2;
}

.border-left-light-grey {
  border-left: 1px solid $color-grey-2;
}

.pd-top-10 {
  padding-top: 10px;
}

.no-link-decor,
.no-link-decor:hover {
  text-decoration: none;
  color: inherit;
}

.blue-big-text {
  color: $color-blue-2;
  font-size: 17px;
}

.width-100 {
  width: 100%;
}

.box-shadow-grey-1 {
  box-shadow: 0px 1px 4px $color-grey-1;
  border: 1px solid $color-grey-3;

  &.hover {
    &:hover {
      box-shadow: 10px 10px 8px $color-grey-1;
      transition: all 0.1s ease-in-out;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.show-menu {
  transform: unset;
  -webkit-transform: unset;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.hide-menu {
  transform: translate(-8vw);
  -webkit-transform: translate(-8vw);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  z-index: 3;
  padding-right: 0 !important;
  position: fixed;
  height: 100%;
}

.hide-menu~.page-container-default {
  margin-left: 4vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 4vw);
}

.hide-menu.overlay-menu {
  margin-left: 8vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 8vw);
}

.hide-menu.overlay-menu~.page-container-default {
  margin-left: 4vw;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  width: calc(100% - 4vw);
}

.page-container-default {
  //margin-left: 12vw;
  padding: 15px 0 0 0;
  // padding-top: 20px;
  width: calc(100%);
  transition: 0.5s;
  -webkit-transition: 0.5s;
}

.custom-slim-scrolbar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $color-grey-1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey-1;
    // outline: 1px solid $color-grey-1;
  }
}

.overflow-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5 {
  opacity: 1 !important;
}

.purina-logo-css {
  background-color: $color-purina-logo-bg;
}

.margin-top-55 {
  margin-top: 55px;
}

// max width
.mw-20px {
  max-width: 20px;
}

// .show-menu {
//   transform: unset;
//   -webkit-transform: unset;
//   transition: 1s;
//   -webkit-transition: 1s;
// }

.section-divider {
  color: #c3c7cb;
  height: 2px;
  margin-top: 0;
  margin-bottom: 0;
}

.custom-container {
  margin-left: 30px;
  margin-right: 30px;
}

.product-gaps-dash-table-custom-scrollbar {
  overflow: auto;
  height: 40vh;
  thead th {
      position: sticky;
      top: -1px;
      z-index: 1;
      border-bottom: 0;
  }
}

.retailscape-table {
  thead {
    border-bottom: solid transparent;
    tr {
      height: auto;
      th {
        background-color: #F7F7F7;
        border-bottom: 1px solid #eeeeee;
        //white-space: nowrap;
      }
    }
  }

  tr {
    height: 50px;
    vertical-align: top;
    border-bottom: 1px solid #eeeeee;
    .image-column {
      width: 120px;
    }

    th {
      color: #666666;
      font-weight: 600;
      a {
        padding: 0;
        color: #898989;
        font-weight: 600;
        text-align: left;
      }
    }

    td {
      border-bottom: 0;
      //color: #333333;
      font-weight: 100;
      a {
        padding-right: 5px;
      }
    }
  }
}

.dropdown-menu-column {
  z-index: 4 !important;
}

.breadcrumb {
  font-weight: 500;
  color: #000000;

  .active {
    color: #888888;
  }
}

.refresh-date {
  color: #000000;

  strong {
    color: #888888;
  }
}

.filters {
  background: #f7f7f7;
  padding: 0px 30px;
}

.apply-button {
  height: 30px;
  width: 70px;
  font-size: 12px !important;
  color: #0069bf;
}

.clear-all-button {
  margin-right: 8px;
  margin-top: 12px;
  color: #0069bf;
  font-weight: 780;
}

.filter-button-group {
  margin-right: -30px !important;
  margin-top: 12px !important;
}

.filter-show-hide {
  position: absolute;
  top: -29px;
  right: 40px;
}

.css-13cymwt-control, .css-t3ipsp-control, .css-1ocacrl-control , .css-urqyr6-control {
  min-height: 25px !important;
  font-size: 10.5px;
  border-color: #ced4da;
}

.css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer{
  padding: 1px 3px !important;
}

.css-g1d714-ValueContainer {
  padding: 0px 3px !important;
}
.css-319lph-ValueContainer, .css-1fdsijx-ValueContainer, .css-3w2yfm-ValueContainer, .css-hlgwow {
  padding: 0px 4px !important;
}
.css-1rhbuit-multiValue, .css-1nmdiq5-menu {
  z-index: 2 !important;
  position: relative;
}
.css-1ydk3de {
  padding: 2px !important;
  padding-left: 6px !important;
}
.css-qbdosj-Input {
  padding-top: 0;
  padding-bottom: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.custom-row {
  min-height: 69px;
  border: 1px solid #eeeeee;
  text-align: left;
}

.custom-row-data {
  border-right: 3px solid #eeeeee;
  margin-right: 0px !important;
}

.table-custom-scrollbar-product-gaps {
  height: 84vh;
  overflow-y: scroll;
  overflow-x: hidden;
  thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border-bottom: 0;
  }
}
.products-list {
  height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
  thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    border-bottom: 0;
    //white-space: nowrap;
    min-width: 5.5rem;
  }
  table {
    font-weight: 100;
    thead {
      th {
        padding-top: 15px;
        background-color: #F7F7F7;
      }
    }
    tr {
      &:hover {
        background: linear-gradient(269.91deg, rgba(0, 105, 191, 0.05) 75.06%, rgba(0, 105, 191, 0) 90.28%);
      }
      border-style: hidden;
      th.firstcolumn {
        width: 25%;
      }
      th {
        color: #898989;
        a {
          color: #898989;
          padding: 0;
          font-weight: 600;
        }
      }
      td {
        border-style: hidden;
        padding: 0;
        .custom-row-data {
          padding: 0;
          padding-left: 0.5rem;
          border-right: 0px;
        }
      }
    }
  }
  .my-product-gaps-images {
      display: flex;
      justify-content: center;
      height: 45px;
      width: 45px;
      margin: 5px 5px;
      img {
          max-height: 45px;
          max-width: 45px;
      }
  }
  .selected-row {
    padding:0px;
    margin:0px;
    border-right: 4px solid #0069bf;
    background: linear-gradient(269.91deg, rgba(0, 105, 191, 0.05) 0.06%, rgba(0, 105, 191, 0) 90.28%);
  }
}

.product-details {
  div {
      text-align: left;
      margin-left: 2px;
  }

  div:nth-child(1) {
      color:#333333;
      font-weight: 500;
      font-size: 13px;
      opacity: 0.4;
  }

  div:nth-child(2) {
      color:#333333;
      font-weight: 100;
      font-size: 13px;
  }

  div:nth-child(3) {
      color:#333333;
      font-weight: 500;
      font-size: 13px;
      opacity: 0.4;
  }
}

.cluster-main-img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
}

.product-view {
  border: 1px solid #eeeeee;
  padding: 20px;
  .subtitle {
      color: #888888;
      opacity: 0.8;
      font-weight: 600;
      span {
          font-weight: 400;
      }
  }
  .title {
      color: #333333;
      font-weight: 700;
      font-size: 20px;
      padding-right: 0px;
  }
  .info-buttons {
    button {
      padding: 0px;
      padding-top: 4px;
    }
  }
  .price {
      color: #666666;
      font-weight: 600;
      border-right: 2px solid #b3b3b3;
      span {
          color: #000000;
      }
  }
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  .dropdown-item.active { 
    background-color: rgb(0, 105, 191)
  }
}
.product-view-header {
  border-bottom: 0px;
  position: relative;
  .cluster-select-column-dropdown {
      position: absolute;
      right: 11px;
      top: 11px;
      float: right;
      width: auto;
  }
  div {
      margin-bottom: 2px solid red;
  }
}

.divider {
  border-bottom: 3px solid #f3f3f3;
  margin: 0px 30px;
}

.product-view-table {
  border-top: 0px;
}

.product-gap-data-row {
  border-radius: 100px;
  background: #F2F4F6;
  //opacity: 0.66;
  align-items: left;
  border-top: 7px solid white;
  td {
      padding: 20px;
      padding-left: 5px;
  }
  .my-product-gaps-images {
      display: flex;
      justify-content: center;
      height: 45px;
      width: 45px;
      img {
          max-height: 45px;
          max-width: 45px;
      }
  }
}
#subfilter-container {
  background-color: #F7F7F7;
  border-bottom: 2px solid #eeeeee;
  border-radius: 2px;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-flow: column;
}
}

#product-image {
  align-items: center;
}

.pagination {
  font-weight: 600;
  .border-less {
    .page-link {
      border: 0;
      color: #666666;
      background-color: transparent;
    }
  }
  .active {
    .page-link {
      background-color: transparent !important;
      color: #0d6efd !important;
    } 
  }
  .next, .prev {
    .page-link {
      color: #666666;
      background-color: transparent;
      border-radius: 2px;
    }
  }
  .disabled {
    .page-link {
      color: #c3c7cb !important;
      background-color: transparent !important;
    }
  }
}
.dashed-broder {
  border-style: dashed !important;
}
.border-color {
  border-color: $color-grey-1;
}
.btn-primary {
  background-color: $color-blue-9 !important;
}
.btn-outline-primary {
  color: $color-blue-9 !important;
  &:hover {
    color: #ffffff !important;
    background-color: $color-blue-9 !important;
  }
}
.btn:active {
  border: transparent;
}
.filter-name {
  position: relative;
  //z-index: 3;
  .name-top {
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 15px;
    background-color: #f7f7f7;
    padding: 5px;
    padding-top: 0;
    font-size: 12px;
    padding-bottom: 0;
  }
}
.middle-button-container {
  position: relative;
  top: 111px;
  left: 50%;
  background-color: #F6F7F9;
  padding: 6px;
  width: fit-content;
  margin-left: -23px;
}
.dark-shade {
  background-color: #F6F7F9;
}
.filter-header {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 3px solid #888888;
    width: 15px;
  }
}
.right-section-header {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  width: 50%;
  margin-bottom: 6px;
  &::before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    border-bottom: 2px solid #0071CE;
    width: 20px;
  }
}
.row-sections {
  table {
    tr {
      th {
        color: #666666;
        font-size: 14px;
        font-weight: 600;
      }
      td {
        color: #333333;
        font-size: 13px;
        text-transform: capitalize;
      }
    }
  }
}
.separator-hr {
  color: #666666;
}
.unableToMapModal {
  table {
    td {
      text-transform: capitalize;
    }
  }
}