@import "../global.scss";
.brand-logo-container {
    margin-left: -1.5rem;
    margin-top: -1.5rem;
    padding-top: 1rem;
    .navbar-brand {
        padding: 1rem 1.5rem;
        background-color: #064C86;
    }
}
.brand-slogan-container {
    font-size: 14px;
    padding-right: 1rem;
    a {
        color: #ffffff;
        text-decoration: none;
        &:hover {
            color: #ffffff;
        }
    }
}
.bungee-nav {
    height: 39px;
    .dropdown-item.active, .dropdown-item:active {
        background-color: #0069BF;
    }
}
.bungee-gaps-nav {
    .dropdown-item {
        font-weight: bold;
        border-bottom: 2px solid #eeeeee;
        padding: 0 10px;
    }
    .dropdown-item:focus, .active {
        background-color: transparent !important;
        color: #0069BF !important;
        border-bottom: 2px solid #0069BF;
    }
}
.menu-items {
    margin-left: 1px;
    font-size: 13px;
    &.active {
        .dropdown-toggle{
            color: #fff !important;
        }
    }
}