@import "../global.scss";

.back-drop{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $color-black-opacity-24; //#cccccc;
    z-index: 999;
    left: 0;
    top: 0;
}

/*.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    transform: translate(-50%, -50%);
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    z-index: 999;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}*/

.loader {
    --clock-color: rgb(21, 46, 77);
    --clock-width: 100px;
    --clock-radius: calc(var(--clock-width) / 3);
    --clock-minute-length: calc(var(--clock-width) * 0.4);
    --clock-hour-length: calc(var(--clock-width) * 0.2);
    --clock-thickness: 4px;
  
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--clock-width);
    height: var(--clock-width);
    border: 4px solid var(--clock-color);
    border-radius: 50%;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 999;
  
    &::before,
    &::after {
      position: absolute;
      content: "";
      top: calc(var(--clock-radius) * 0.25);
      width: var(--clock-thickness);
      background: var(--clock-color);
      border-radius: 10px;
      transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
      animation: spin infinite linear;
    }
  
    &::before {
      height: var(--clock-minute-length);
      animation-duration: 2s;
    }
  
    &::after {
      top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
      height: var(--clock-hour-length);
      animation-duration: 15s;
    }
  }
  
  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }